import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BoutonContinuer from "../../composants/boutons/BoutonContinuer"
import Image from "../../composants/image/Image"
import LayoutFR from "../../composants/layout/fr/LayoutFR"
import ListeCartesTitreSeul from "../../composants/navigation/ListeCartesTitreSeul"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirPages,
} from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"
import Lien from "../../composants/navigation/Lien"

export const requete = graphql`
  {
    pageBoutique: allPagesCompilationYaml(
      filter: { code: { eq: "boutique" } }
    ) {
      ...FragmentPageYaml
    }
    imageAstrophotographieAvecUnReflex: allImagesCompilationYaml(
      filter: { code: { in: ["astrophotographieavecUnReflex"] } }
    ) {
      ...FragmentImagePaysageTresPetit
    }
    imagesBoutique: allImagesCompilationYaml(
      filter: { code: { in: ["boutiqueMiseEnScene"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    galeriesVenteTirages: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "venteTiragesVoieLactee"
            "venteTiragesNebuleuses"
            "venteTiragesGalaxies"
            "venteTiragesAuroresBoreales"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersVenteTirages
    }
  }
`

export default function Boutique() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageBoutique")
  const images = obtenirImages(
    resultatsRequete,
    "imageAstrophotographieAvecUnReflex",
    "imagesBoutique",
    "imagesPortraitMoyen"
  )
  const galeriesVenteTirages = obtenirPages(
    resultatsRequete,
    "galeriesVenteTirages"
  )

  return (
    <LayoutFR page={page}>
      <Section titre=" Astrophotographie avec un réflex (ou un hybride…)">
        <p>
          Pour vous aider à débuter ou à progresser en astrophotographie avec
          votre réflex ou votre hybride, un livre électronique de{" "}
          <strong>215 pages</strong> de techniques issues de plus de 15 années
          de pratique de la discipline.
        </p>
        <p>
          <strong>Au sommaire :</strong>
        </p>
        <ul>
          <li>
            Pour chaque thème, des expériences terrain et un tutoriel détaillé
            de traitement avec Photoshop.
          </li>
          <li>Voie lactée et arches de Voie lactée.</li>
          <li>Aurores boréales.</li>
          <li>Pluies d’étoiles filantes.</li>
          <li>
            Ciel profond (nébuleuses, galaxies…) <strong>(43 pages)</strong>.
          </li>
          <li>Lune et comètes.</li>
          <li>Visites virtuelles.</li>
          <li>Imprimer ses astrophotographies.</li>
          <li>…</li>
        </ul>
        <div
          id="boutique-conteneur-astrophotographie-reflex"
          className="conteneur-cote-a-cote conteneur-sans-marge"
        >
          <Lien
            codePageCible="astrophotographieReflex"
            classeCSS="lien-sans-effet"
          >
            <Image
              image={obtenirImage(images, "astrophotographieavecUnReflex")}
              afficherLegende={false}
              afficherOmbre={false}
              langue={page.langue}
            />
          </Lien>
          <div>
            <BoutonContinuer
              texte="Plus d’informations"
              codePageCible="astrophotographieReflex"
              classeCSS="bouton bouton-continuer"
            />
          </div>
        </div>
      </Section>
      <Section titre="Tirages photos astronomie">
        <p>
          Des tirages pigmentaires, garantie d’une excellente tenue dans le
          temps, <strong>de mes belles astrophotographies</strong>. Certaines
          ont été distinguées internationalement.
        </p>
        <p>
          Les tirages sont réalisés <strong>artisanalement</strong> et sont
          fournis avec un certificat d’authenticité signé par l’auteur. Ils sont
          numérotés et ne peuvent être reproduits qu’à 30 exemplaires au maximum
          quels que soient leurs formats. Assurément{" "}
          <strong>un cadeau idéal</strong> pour tous les amoureux de l’espace,
          des étoiles et de l’Univers.
        </p>
        <Image
          image={obtenirImage(images, "boutiqueMiseEnScene")}
          afficherLegende={false}
          afficherOmbre={false}
          langue={page.langue}
        />
        <h3>Les collections</h3>
        <ListeCartesTitreSeul pages={galeriesVenteTirages} id="galeries-nav" />
      </Section>
    </LayoutFR>
  )
}
